<template>
    <div class="resource_list">
        hello world
    </div>
</template>

<script>
export default {
    name: 'ResourceList',

    setup() {
        return {

        }
    }
}
</script>

<style scoped>
.resource_list {
    padding-top: 70px;
}
</style>