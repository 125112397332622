<template>
    <div class="article">
        <div clas="creater-info">
            <UserCard :user="creater" />
        </div>
        <div class="no_conetnt" v-if="article.content === null">
            暂无文章，请稍后再试。
        </div>
        <div class="content" v-else>
            <div v-html="vue_markdown.render(article.content)"></div>
        </div>
        <div class="comments">
            <div class="comment-title">
                评论
            </div>
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue';
import MarkdownIt from 'markdown-it';
import { useStore } from 'vuex';
import { getArticleByID_get } from '../assets/script/article_request.js';
import { getUserInfo_get } from '../assets/script/user_request.js';
import UserCard from './UserCard.vue';

export default {
    name: 'ArticleInfo',
    components: {
        UserCard
    },
    setup() {
        const article = ref({});
        const store = useStore();
        const vue_markdown = new MarkdownIt();
        const creater = ref(null);

        onMounted(async () => {
            // 获取路由参数
            const id = window.location.href.split('/').pop();
            // 获取根据id获取文章详情
            const res = await getArticleByID_get(store.state.token, id)
            if (res.code !== 0) {
                return;
            }
            article.value = res.data;
            // 获取作者信息
            const res2 = await getUserInfo_get(store.state.token, article.value.createUser)
            if (res2.code !== 0) {
                return;
            }
            creater.value = res2.data;

        })
        return {
            article,
            creater,
            vue_markdown
        }
    }
}
</script>

<style scoped>
.article {
    padding-top: 70px;
    /* 其中元横排居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.content {
    width: 60%;
}

.creater-info {
    width: 20%;
}

.comments {
    width: 20%;
    /* 其中元素竖排居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comment-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
}
</style>